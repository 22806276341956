<template>
  <v-container fluid class="pb-5">
    <v-row>
      <v-col>
        <h3 class="mb-2">Comments</h3>

        <v-simple-table>
          <thead>
            <tr class="pb-4">
              <th>Date</th>
              <th>By</th>
              <th>Comment</th>
              <th v-if="hasSEAPermissions">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="i in items" :key="i.id">
              <td>{{ datatables_formatTimestamp(i.entry_at) }}</td>
              <td>{{i.entry_by}}</td>
              <td>{{i.comment}}</td>
              <td v-if="hasSEAPermissions">
                <v-btn icon @click="showEditItem(i.id, i.comment)">
                  <v-icon small>edit</v-icon>
                </v-btn>
                <v-btn icon @click="deleteItem(i.id)">
                  <v-icon small>delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-btn v-if="hasSEAPermissions" class="mt-3" color="primary" @click="showCreateItem()">Add comment</v-btn>

        <v-dialog v-model="showModal" persistent max-width="600px">
          <v-card>

            <v-card-title>
              <span class="headline">{{modalMode}} comment</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-form ref="form">
                      <v-text-field v-model="comment" label="Comment" required :rules="[v => !!v || 'Comment is required']"></v-text-field>
                    </v-form>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeModal()">Close</v-btn>
              <v-btn color="blue darken-1" text @click="save()">Save</v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import auth from '@/auth'
import formsMixin from '@/mixins/forms'
import dataTablesMixin from '@/mixins/datatables'

export default {
  name: 'NKWComments',

  props: {
    nkwID: { type: Number, required: true },
  },

  data () {
    return {
      items: [],
      item: {
        entry_at: null,
        entry_by: null,
        comment: null,
      },
      valid: false,
      showModal: false,
      modalMode: null, // Add or Edit
      loading: false,
      selectedID: null,
      comment: null,
      dataEndpoint: '/a/nkw/comments',
    }
  },

  mixins: [formsMixin, dataTablesMixin],

  computed: {
    hasSEAPermissions () {
      if (auth.hasPermission('SEA Basic') || auth.hasPermission('Tech')) {
        return true
      }
      return false
    },
  },

  methods: {
    closeModal: function () {
      this.selectedID = null
      this.comment = null
      this.showModal = false
    },

    createItem: function () {
      // make sure all mandatory fields have a value
      if (!this.$refs.form.validate()) {
        return
      }
      var newItem = this.getItem()
      var body = JSON.stringify(newItem)
      this.$http.post(this.dataEndpoint, body).then(resp => {
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.dataEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.closeModal())
    },

    deleteItem: function (id) {
      if (!confirm('Are you sure?')) {
        return
      }

      var myURL = this.dataEndpoint + '/' + id

      this.$http.delete(myURL).then(resp => {
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'DELETE', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
    },

    editItem: function () {
      // make sure all mandatory fields have a value
      if (!this.$refs.form.validate() || !this.selectedID) {
        return
      }
      var newItem = this.getItem()
      var body = JSON.stringify(newItem)
      var myURL = this.dataEndpoint + '/' + this.selectedID
      this.$http.put(this.dataEndpoint + '/' + this.selectedID, body).then(resp => {
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'PUT', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.closeModal())
    },

    fetchData: function () {
      this.loading = true

      var myURL = this.dataEndpoint + '?xsort=-entry_at&xper_page=5&xfields='
      // only get the fields needed to fill the item object
      myURL = this.forms_addItemFieldsToURI(myURL, this.item, ['id'])

      // filter for this NKW only
      myURL += '&keywords_fk=' + this.nkwID

      this.$http.get(myURL).then(resp => {
        this.items = resp.data.data
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    getItem: function () {
      // clone current item (using = will copy by reference)
      var newItem = JSON.parse(JSON.stringify(this.item))

      // change fields based on form values
      newItem['keywords_fk'] = this.nkwID
      newItem['comment'] = this.comment

      // remove extra fields not used by POST/PUT
      delete newItem['entry_by']
      delete newItem['entry_at']

      return newItem
    },
    
    save: function () {
      if (this.modalMode === 'Add') {
        this.createItem()
      } else {
        this.editItem()
      }
    },

    showEditItem: function (id, comment) {
      this.selectedID = id
      this.comment = comment
      this.modalMode = 'Edit'
      this.showModal = true
    },
    showCreateItem: function () {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      
      this.modalMode = 'Add' 
      this.showModal = true
    }
  },

  created: function () {
    this.fetchData()
    this.timer = setInterval(this.fetchData, this.datatables_refreshIntervalMs)
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>

<style scoped>
</style>